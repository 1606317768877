.form--product {
  padding: 0 0 5rem;
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
  &__form {
    // background-color: red;
    row-gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25rem;
    input, label{
      font-size: 1.4rem;
    }
    input[type="file"]{
      margin-bottom: 1rem;
    }
    &--image{
      display: block;
      margin: 0 auto 2rem;
      width: 15rem;
      height: 15rem;
      object-fit: cover;
      border-radius: 50%;
      border: solid 2px $white;
    }
    &--inventario {
      &--stock {
       
        display: flex;
        justify-content: center;
        gap: 1rem;
        .stock--talla{
          width: 40%;
        }
        .stock--cantidad{
          width: 40%;
        }
        img{
          display: block;
          cursor: pointer;
          transition: all 500ms;
          &:hover{
            transform: scale(1.1);
          }
        }
      }
      &--view--stock {
        margin-top: 2rem;
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        .view--stock__title{
          p{
            margin: 1rem 0
          }
        }
        .view--stock__inventario{
          @include flexCenter();
          flex-direction: column;
          row-gap: 1rem;
          p{
            @include flexCenter();
            background-color: $white;
            color: $black;
            border-radius: 50%;
            padding: 0.5rem;
            margin: 0;
            width: 3rem;
            height: 3rem;
          }
          .view--stock__inventario--delete{
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
            border-radius: 50%;
            background-color: $red;
            border: none;
            color: $white;
            font-weight: 100;
            cursor: pointer;
            text-align: center;
          }
        }
      }
    }
  }
}
