.page--tienda {
    &__container {
      display: flex;
      flex-direction: column;
      @include mq(l) {
          flex-direction: row;
          justify-content: space-between;
      }
      &--left {
        width: 100%;
        height: 25vh;
        min-height: 25rem;
        @include mq(l) {
          width: 20%;
        }
        .seccion--white{
          background-color: $gray-lite;
        }
      }
      &--right {
        width: 100%;
        min-height: 45rem;
        height: 70vh;
        @include mq(l) {
          width: 80%;
          height: 100vh;
          overflow-y: scroll;
        }

      }
    }
  }
  