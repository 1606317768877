.politicas{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
    &__container{
        width: 80%;
        margin: auto;
        max-width: 900px;
        @include mq(sm) {
            width: 50%;
        }
        h2, h3{
            text-align: center;
            margin: 3rem 0;
        }
        h4{
            font-size: 1.6rem;
        }
        p{
            text-align: justify;
        }
    }

}