form {
    height: 100%;
  label,
  button {
    font-size: 1.2rem;
    @include mq(m) {
      font-size: 1.6rem;
    }
  }

  input, select {
    background: transparent;
    border: none;
    background-image: url("../../img/bgs/bg-input.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 100%;
    width: 25rem;
    color: $white;
    text-align: left;
    outline: none;
    font-size: 1.2rem;
    &::placeholder{
      color: $gray;
    }
    @include mq(m) {
      font-size: 1.6rem;
    }
  }
  select{
    width: 25.5rem;
    font-size: 1.2rem;
    height: 2rem;
  }
}
button {
  width: 100%;
  font-weight: 700;
  font-family: $roboto;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  gap: 2rem;
  cursor: pointer;
  transition: all 200ms;
  // &:hover{
  //     transform: scale(1.05);
  // }
  @include mq(m) {
    font-size: 1.6rem;
  }
  img{
      width: 2.5rem;
  }
}
