.search--btn {
    &__input {
        width: 60%;
        display: flex;
        gap: 1rem;
        margin: 1rem 0 5rem;
        // background-color: $gray-lite;
        padding: 0.5rem 1rem;
        border-radius: 2rem;

        &--containers {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: calc(100% / 5);
            gap: 0.5rem;
            label{
                color: $white;
                font-size: 1.2rem;
            }
            input,
            select {
                text-align: center !important;
                // border: solid 1px $black !important;
                color: $black !important;
                font-size: 1.2rem !important;
                padding: 1rem;
                background-color: $gray-lite !important;
                border-radius: 2rem;
                width: 90%;
                &::placeholder {
                    font-size: 1rem;
                    color: $black !important;
                }
            }
        }
        .btn__search{
            width: 20rem;
        }
    }
}