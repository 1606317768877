.popup{
    background-color: $blackOpacity;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    animation: inNode 1s;
    &__container{
        display: flex;
        flex-direction: column;
        width: 60%;
        justify-content: center;
        align-items: center;
        height: 100vh;
        margin: auto;
        max-width: 50rem;
    }
    &__title{
        text-transform: uppercase;
        font-size: 2rem;
    }
    &__close{
        width: 5rem;
        height: 5rem;
        margin: 0 0 0 auto;
        border-radius: 10rem;
        transition: all 500ms;
        &:hover{
            transform: rotate(180deg);
        }
    }
    &__image{
        width: 32rem;
        height: 30rem;
        object-fit: cover;
        border: solid 4px $gray-lite;
        border-radius: 2rem;
        @include mq(m){
            width: 35rem;
            height: auto;
        }
    }
    &__descripcion{
        text-align: center;
    }
}