@mixin mq($bp) {
    $bp-value: map-get($breakpoints-min, $bp);
  
    @media only screen and (min-width: $bp-value) {
      @content;
    }
  }


  @mixin flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }