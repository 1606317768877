.animation--home{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    &__logo{
        display: block;
        width: 20%;
        animation: appear 2s;
    }
}