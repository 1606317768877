.view--stock__inventario{
    @include flexCenter();
    flex-direction: column;
    row-gap: 1rem;
    p{
      @include flexCenter();
      background-color: #393939;
      color: $gray-lite;
      border-radius: 50%;
      padding: 0.5rem;
      margin: 0;
      width: 3rem;
      height: 3rem;
    }
    .view--stock__inventario--delete{
      width: 2.5rem;
      height: 2.5rem;
      padding: 0;
      border-radius: 50%;
      background-color: $red;
      border: none;
      color: $white;
      font-weight: 100;
      cursor: pointer;
      text-align: center;
    }
  }