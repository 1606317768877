.seccion--white {
  background-image: url("../../../img/bgs/bg-section-white-m.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 100%;
  color: $black;
  // white two
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 2;
  @include mq(l) {
    background-image: url("../../../img/bgs/bg-section-white.png");
    width: 20%;
    min-height: 100vh;
    background-size: cover;
    background-position: right;
  }
  &__container {
    height: 16rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    @include mq(l) {
      height: 100vh;
    }
    .menu--movile__container{
      @include flexCenter();
      flex-direction: column;
      .logo--site{
        &__logo{
          width: 8rem;
        }
      }
      img{
        width: 5rem;
      }
    }
 
  }

  .seccion--search {
    display: none;
    @include mq(sd) {
      display: initial;
    }
  }
  .seccion--search__movile {
    background-color: $gray-lite;
    top: 100%;
    position: absolute;
    z-index: 5;
    height: 100vh;
    width: 77%;
    animation: 1s inNode;
    right: 0;
  }
  .seccion--search__movile--close {
    animation: 1s outNode;
  }
  .close--menu__movile {
    transition: 1s all;
    transform: rotate(360deg);
  }
  .close--menu__movile--out {
    transition: 1s all;
    transform: rotate(-90deg);
  }
  .copy--site {
    display: none;
    @include mq(sd) {
      display: initial;
    }
  }
}

