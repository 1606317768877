.modal--order {
  position: absolute;
  gap: 10rem;
  z-index: 10;
  background-color: $blackOpacity;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .btn--close{
    position: fixed;
    margin: 0;
    background-color: $black;
    top: 70%;
    right: 5%;
    @include mq(l){
      right: 52%;
      position: absolute;
    }
  }
  &__close {
    position: absolute;
    top: 5%;
    left: 10%;
    cursor: pointer;
    transition: 500ms all;
    &:hover {
      transform: scale(1.1);
    }
  }
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    height: auto;
    @include mq(l){
      flex-direction: row;
      height: 100vh;
    }
  }
  &__left {
    width: 100%;
    @include mq(l){
      width: 50%;
      height: 100vh;
      overflow: auto;
      margin-bottom: 5rem;
      padding-top: 5rem;
    }
    .numero-pedido{
      text-align: center;
      margin: 3rem 0;
    }
  }
  &__right {
    width: 100%;
    overflow-y: scroll;
    height: auto;
    margin-bottom: 5rem;
    @include mq(l){
      width: 50%;
      height: 100vh;
      margin: 0;
    }
  }
  .check--order__edit{
    @include mq(l){
      // width: 50%;
      height: auto;
    }
    &--total{
      h2{
        margin-top: 0;
        text-align: center;
        color: $black;
        font-size: 3rem;
      }
    }
  }
}
