.swal-modal{
    background-color: $black;
    border: solid 5px rgb(97, 97, 97);
    border-radius: 2rem;
    .swal-title{
        color: $white;
    }
    .swal-text{
        color: $white;
    }
    .swal-footer{
        .swal-button-container{
            .swal-button{
                background-color: $gray-lite;
                color: $black;
            }
        }
    }
    .swal-icon{
        &::after{
            display: none;
        }
        &::before{
            display: none;
        }
    }
}