.page--base {
  &__container {
    display: flex;
    flex-direction: column;
    @include mq(l) {
        flex-direction: row;
    }
    &--left {
      width: 100%;
      height: 25vh;
      min-height: 25rem;
      @include mq(l) {
        width: 40%;
      }
    }
    &--right {
      width: 100%;
      height: 70vh;
      min-height: 45rem;
      @include mq(l) {
        width: 60%;
      }
    }
  }
}
