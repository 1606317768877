
$black: #1E1E1E;
$blackOpacity: #1e1e1efc;
$gold: #d6c283;
$gold-dark: #938353;
$gold-tab: #d6c282;
$red: #763641;
$gray: #7B7B7B;
$gray-lighter: #72777a;
$gray-content: rgba(0, 0, 0, 0.6);
$gray-texy: #aeaeae;
$gray-resutl: #495057;
$bg-hover: #fffbf0;
$br-hover: #fdf3d7;
$color-hover: #f5e6b6;
$gold-feature: #877B52;
$purple: #270a45;
$white :#FFFFFF;
$gray-lite: #E8E8E8;

$bold: 700;
$boldBetter: 600;
$maxwidth: 35rem;
$maxwidthBody: 24rem;
$maxwidth1200: 120rem;
$maxwidth1300: 130rem;
$maxbutton: 40rem;


$roboto : 'Roboto';

// Media queries
$breakpoints-min: (
  "s": 576px,
  "m": 768px,
  "l": 992px,
  "xl": 1300px,
  "xls": 1400px,
  "xxl": 1600px,
);