.order--time__line {
  @include flexCenter();
  flex-direction: column;
  &--items {
    @include flexCenter();
    img {
      opacity: 0.1;
      width: 4rem;
      @include mq(m) {
        width: 5rem;
      }
    }
    hr {
      opacity: 0.1;
        width: 3rem;
        @include mq(m) {
          width: 5rem;
        }
    }
    .activado{
      opacity: 1;
    }
  }
  &--msg{
    text-align: center;
    display: flex;
    .spinner{
      position: static;
      .lds-spinner{
        position: initial;
      }
    }
    .guia--info{
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: center;
      a{
        text-decoration: none;
      }
    }
  }
  .guia--imagen{
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include mq(l){
      width: 50%;
    }
    img{
      width: 90%;
      margin: auto;
    }
    a{
      background-color: $white;
      width: 90%;
      padding: 1rem 0;
      margin: auto;
      display: block;
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: 700;
      color: $black;
    }
  }
  .pedido__agendado{
    width: 4.5rem;
    margin: auto;
  }
}
