.check--order {
  // background-color: $gray-lite;
  border-radius: 2rem;
  padding: 5rem 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  @include mq(l) {
    width: 80%;
    max-width: 120rem;
    margin-top: 5rem;
  }
  &__producto {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1.5rem;
    color: $black;
    margin-bottom: 1rem;
    .one,
    .two,
    .three,
    .four {
      background-color: $white;
      width: 21%;
      height: 7rem;
      border-radius: 1rem;
      h2,
      h3 {
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
      }
      p {
        font-size: 1rem;
      }
      @include mq(m) {
        width: 21%;
        height: 8rem;
      }
      @include mq(l) {
        background-color: $white;
        width: 21%;
        height: auto;
        border-radius: 2rem;
        h2,
        h3 {
          font-size: 1.4rem;
        }
        p {
          font-size: 1.4rem;
        }
      }
    }
    .one {
      @include flexCenter();
      img {
        display: block;
        margin: auto;
        width: 90%;
        height: 6rem;
        object-fit: cover;
        border-radius: 1rem;
        @include mq(m) {
          width: 90%;
          height: 7rem;
        }
        @include mq(l) {
          width: 90%;
          height: 12rem;
        }
      }
    }
    .two {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__content {
        @include flexCenter();
        gap: 0.4rem;
        text-align: center;
        height: 3.5rem;

        div {
          width: 50%;
        }
        h3 {
          margin: 0;
          color: $red;
        }
        p {
          background: $gray-lite;
          padding: 0.5rem 0;
          border-radius: 1rem;
          margin: 0;
          color: $red;
          font-weight: 700
        }
        @include mq(l) {
          width: 90%;
          height: 10rem;
          div {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
          }
          h3 {
            margin: 0;
          }
          p {
            background: $gray-lite;
            padding: 1rem 0;
            border-radius: 2rem;
            margin: 0 auto;
            width: 4rem;
            font-size: 2rem;

          }
        }
      }
    }
    .three {
      @include flexCenter();
      font-weight: 700;
      p {
        font-size: 1.2rem;
        @include mq(l) {
          font-size: 2.5rem;
        }
      }
    }
    .four {
      @include flexCenter();
      flex-direction: column;
      gap: 1rem;
      background-color: $red;
      @include mq(l) {
        background-color: $white;
      }
      &__delete,
      &__edit {
        width: 90%;
        padding: 0.5rem 0;
        border-radius: 2rem;
        border: none;
        cursor: pointer;
        transition: 500ms all;
        color: $white;
        font-weight: 500;
        &:hover {
          transform: scale(1.1);
        }
        @include mq(l) {
          width: 70%;
          padding: 1rem;
        }
      }
      &__delete {
        background-color: $red;
      }
      &__edit {
        background-color: $black;
      }
    }
  }
  &__footer {
    @include mq(l) {
      @include flexCenter();
      justify-content: space-between;
      width: 90%;
      margin: 3rem auto 0;
      gap: 2rem;
    }
  }
  &__info {
    @include flexCenter();
    justify-content: center;
    text-align: center;
    gap: 2rem;
    margin: 1rem 0 3rem;
    @include mq(m) {
      width: 90%;
      margin: 1rem auto 3rem;
    }
    @include mq(l) {
      width: 60%;
      margin: 0 auto;
    }
    div {
      width: 50%;
      @include flexCenter();
      gap: 1rem;
      background-color: $white;
      border-radius: 2rem;
      color: $red;
      padding: 1rem 0;
      p {
        margin: 0;
        font-size: 2rem;
        font-weight: 700;
      }
    }
  }
  &__end {
    @include mq(l) {
      display: flex;
      justify-content: flex-end;
      width: 40%;
      margin: 0;
    }
    button {
      background-color: $red;
      border: none;
      border-radius: 2rem;
      padding: 1rem;
      color: $white;
      font-size: 2rem;
      width: 90%;
      margin: auto;
      @include mq(l) {
        margin: 0;
      }
    }
  }
}
