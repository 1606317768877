html {
  background-color: $black;
  font-size: 10px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $roboto;
  color: $white;
}

h1 {
  font-size: 2.5rem;
  @include mq(m) {
    font-size: 3rem;
  }
}
h2 {
  font-size: 2rem;
  @include mq(m) {
    font-size: 2.5rem;
  }
}
h3 {
  font-size: 1.6rem;
  @include mq(m) {
    font-size: 2rem;
  }
}
p {
  font-size: 1.2rem;
  @include mq(m) {
    font-size: 1.6rem;
  }
}

.lkjDVC {
  border-color: $white !important;
  svg{
    color: $white !important;
  }
}
.dmajdp > span {
  color: white !important;
}