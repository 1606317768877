.seccion--search__category {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 60vh;
  &--list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 80%;
  }
  &--item {
    list-style: none;
    width: 100%;
    button {
      width: 100%;
      display: block;
      padding: 0.5rem 0 1rem 1rem;
      background: transparent;
      border: none;
      background-image: url("../../../img/bgs/bg-category.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position-y: 100%;
      width: 100%;
      color: $black;
      text-transform: uppercase;
      text-align: left;
      outline: none;
      font-size: 1.2rem;
      margin: 0;
      transition: all 500ms;
      &:hover {
        font-size: 1.4rem;
        color: green;
        font-weight: 700;
      }
    }
  }
}

.seccion--search__search {
  display: flex;
  padding: 1rem 0;
  margin: 1rem;
  input {
    width: 70%;
    border: none;
  }
  button {
    width: 30%;
    border: none;
    color: white;
    background-color: $black;
  }
}

.menu--desktop__container {
  @include flexCenter();
  width: 100%;
  flex-direction: column;
  @include mq(l){
    height: 100%;
  }
  .seccion--search__desktop {
    display: flex;
    padding: 1rem 0;
    margin: 1rem;
    input {
      width: 70%;
      border: none;
    }
    button {
      width: 30%;
      border: none;
      color: white;
      background-color: $black;
    }
    &--menu {
        width: 90%;
        overflow-y: scroll;
        overflow-x: none;
        height: 70vh;
        padding: 2rem 2rem 0 2rem;
        @include mq(l){
          height: 50vh;
        }
      .seccion--search__category--list {
        padding: 0;
        margin: 0;
        width: 100%;
        .seccion--search__category--item {
          button {
            &:hover {
              font-size: 1.2rem;
              color: green;
              font-weight: 700;
            }
            &:active{
                color: green;
            }
          }
        }
      }
    }
  }
}
