.page--orders {
  position: relative;

  &__container {
    display: flex;
    flex-direction: column;

    @include mq(l) {
      flex-direction: row;
      justify-content: space-between;
    }

    &--left {
      width: 100%;
      height: 25vh;
      min-height: 25rem;

      @include mq(l) {
        width: 15%;
      }

      .seccion--white {
        background-color: $gray-lite;
      }
    }

    &--right {
      width: 100%;
      min-height: 45rem;
      height: auto;
      @include flexCenter();
      justify-content: flex-start;
      flex-direction: column;

      @include mq(l) {
        width: 100%;
        margin: auto;
        height: 100vh;
        // overflow-y: scroll;
      }

      @include mq(xl) {
        width: 100%;
      }

      .products--list {
        width: 90%;

        // overflow-x: scroll;
        @include mq(l) {}

        .produts--list__actions {
          @include flexCenter();
          gap: 2rem;
          justify-content: flex-start;
          flex-direction: column;
          width: 100%;
          margin: 3rem 0 5rem;

          @include mq(l) {
            flex-direction: row;
            max-width: 160rem;
            margin: auto;
          }

          // .search--btn{
          //   margin: 0;
          //   width: 20rem;
          // }

          button {
            display: flex;
            padding: 1rem;
            margin-bottom: 0rem;
            width: 20rem
          }

          .produts--list__actions--add {
            margin-bottom: 1.5rem;
          }
        }
      }

      .page--orders__header {
        @include flexCenter();
        width: 90%;
        max-width: 130rem;
        margin: 5rem 0;
        gap: 4rem;
        flex-direction: column;
        justify-content: space-between;

        @include mq(l) {
          flex-direction: row-reverse;
        }
      }
    }
  }

  input,
  select {
    background: transparent;
    border: none;
    background-image: url("../../../img/bgs/bg-input.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 100%;
    color: $white;
    text-align: left;
    outline: none;
    font-size: 1.2rem;

    &::placeholder {
      color: $gray;
    }

    @include mq(m) {
      font-size: 1.6rem;
    }
  }
}

table tr:nth-child(odd) {
  background: $gray-lite;
}

table tr:nth-child(even) {
  background: $white;
}

/* --------------------------------- tablas --------------------------------- */
.container--table {
  width: 100%;
  margin: auto;
  height: 50vh;
  overflow: auto;
  @include mq(m) {
    height: 50vh;
    overflow: auto;
  }
  @include mq(l) {
    height: auto;
    overflow: auto;
  }
}

.orders--table {
  width: 80%;
  margin: auto;
  border-collapse: collapse;
  // border-spacing: 10px 5px;
  text-align: center;
  color: $black;
  font-size: 1.1rem;
  width: 180rem;

  @include mq(l) {
    width: 100%;
    // width: 120rem;
  }

  // @include mq(xl) {
  //   width: 125rem;
  // }
  // @include mq(xxl) {
  //   width: 180rem;
  // }
  .table--item {
    font-size: 1.2rem;
    height: 7rem;
  }

  .table--header {
    background-color: $gray-lite;
    text-align: center;

    td {
      padding: 2rem 0;
      color: $black;
      font-size: 1.2rem;
      font-weight: 700;
    }
  }

  .table--item__actions {
    @include flexCenter();
    width: 20rem !important;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 1rem auto;
    height: 7rem;

    button {
      width: 35%;
      font-size: 1.1rem;
      background-color: $black;
      border-radius: 2rem;
      color: $white;
      border: none;
      padding: 0.5rem 1rem;

    }

    .table--item__actions--disable {
      background-color: $red;
    }
  }
}


// .container--table.reportes{
//   .orders--table{
//     width: 100%;
//   }
// }
tr {
  width: 100% !important;

  td {
    width: auto !important;
    white-space: pre-wrap;
  }
}