.productos--page {
  max-width: 140rem;
  margin: auto;
  &__header {
    @include flexCenter();
    button {
      background-color: $black;
      color: $white;
      border-radius: 2rem;
      padding: 0.5rem 1rem;
      gap: 0;
      border: none;
      width: 80%;
      p {
        font-family: $roboto;
        width: 90%;
        margin: 0;
        font-weight: 400;
      }
      img {
        width: 2.2rem;
        height: auto;
      }
    }
    .guia--tallas {
      display: flex;
      gap: 1rem;
      align-items: center;
      width: 20rem;
      background-color: $gray-lite;
      padding: 1rem 0;
      margin-bottom: 3rem;
      @include mq(l){
        margin-left: 5%;
      }
      a{
        text-decoration: none;
      }
      p {
        color: $black;
        font-weight: 700;
        width: initial;
      }
    }
    .carrito {
      @include flexCenter();
      top: 1rem;
      position: fixed;
      z-index: 5;
      left: 0;
      margin: 0;
      width: 65%;
      background: $gray-lite;
      flex-direction: column;
      padding: 1rem;
      border-radius: 2rem;
      justify-content: flex-end;
      @include mq(l) {
        position: static;
        width: 25rem;
        margin: 5rem 3rem 5rem auto;
      }

      &--user__name{
        color: $black;
        font-weight: 700;
        font-size: 1.5rem;
      }
      &--info {
        @include flexCenter();
        gap: 2rem;
        p {
          font-weight: 700;
          font-size: 2rem;
          color: $black;
          margin: 0;
          span {
            color: $red;
          }
        }
      }
    }
  }
  &__tienda {
    @include flexCenter();
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    @include mq(l){
      gap: 3rem;
    }
  }
}
