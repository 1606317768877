.admin--panel{
    @include flexCenter();
    background-color: $gray-lite;
    border-radius: 2rem;
    flex-direction: column;
    padding: 1rem 2rem;
    &__user{
        @include flexCenter();
        h2{
            color: $black;
            font-size: 2rem;
        }
    }
    &__actions{
        @include flexCenter();
        flex-direction: column;
        gap: 1rem;
        button{
            @include flexCenter();
            justify-content: space-between;
            border: none;
            margin: 0;
            padding: 0.2rem 1rem;
            background-color: $black;
            color: $gray-lite;
            border-radius: 2rem;
            font-size: 1.4rem;
            transition: all 500ms;
            &:hover{
                transform: scale(1.1);
            }
            img{
                width: 2rem;
                height: auto;
            }
        }
    }
}