.form--query {
  &__form {
    @include flexCenter();
    flex-direction: column;
    row-gap: 2rem;
    input {
      text-align: center;
    }
  }
}
