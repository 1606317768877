.reportes {
  &--search {
    margin: 3rem 0;
    &__form {
      @include flexCenter();
      justify-content: flex-start;
      gap: 2rem;
      flex-direction: column;
      @include mq(l){
        flex-direction: row
      }
      &--input {
        @include flexCenter();
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
      }

      &--btn {
        width: 15rem;
      }
    }
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
    border-radius: 50%;
    padding: 0.5rem 1rem 0.5rem 0;
    cursor: pointer;
  }
}

.container--table.reportes {
  .orders--table {
    width: 100%;
  }
}
