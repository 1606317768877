.page--home{
    &__container{
        .page--base__container--right{
            // flex-direction: column;
            @include flexCenter();
            gap: 3rem;
            @include mq(m){
                min-height: 100vh;
                flex-direction: row
            }
            .user{
                &--verificar, &--order {
                    @include flexCenter();
                    flex-direction: column;
                    gap: 3rem;
                    img{
                        width: 10rem;
                        @include mq(m){
                            width: 15rem;
                        }
                    }
                    a{
                        text-decoration: none;
                    }
                }
            }
        }
    }
}