.guia--PDF{
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: flex-start;
    width: 90%;
    margin: auto;
    &__remitente{
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(3, auto);
        .remitente {
            grid-column: 1 / -1;
            background-color: blue;
        }
        div{
            display: flex;
            gap: 2rem;
        }
    }
    &__destinatario{
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(3, auto);
        .remitente {
            grid-column: 1 / -1;
            background-color: blue;
        }
        div{
            display: flex;
            gap: 2rem;
        }
    }

}

.orders{
    width: 90%;
    padding-bottom: 5rem;
    height: 100%;
    // overflow-x: scroll;
    @include mq(l){
        width: initial;
        width: 100%;
        // max-width: 160rem;
        // overflow-x: scroll;
        // overflow: initial;
    }
    @include mq(xxl){
        max-width: 160rem;
      }
}
.pdf--guia{
    width: 130rem;
    margin: auto;
}
.btn--close{
    margin: 3rem 0 ;
    text-align: right;
    cursor: pointer;
    img{
        transition: all 500ms;
        &:hover{
            transform: rotate(180deg) scale(1.1);
        }
    }
}