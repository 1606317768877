.card--product {
  @include flexCenter();
  width: 16rem;
  background: $gray-lite;
  color: $black;
  border-radius: 2rem;
  gap: 2rem;
  height: auto;
  padding: 1rem 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include mq(l){
    flex-direction: row;
    width: 30rem;
    background: $gray-lite;
    color: $black;
    border-radius: 2rem;
    gap: 2rem;
    height: auto;
    padding: 1rem;
  }
  &.producto--agotado{
    opacity: 0.3;
    pointer-events: none;
  }
  &__left {
    position: relative;
    width: 100%;
    text-align: center;
    @include mq(l){
      width: 50%;
    }
    .image--product {
      width: 12rem;
      height: 12rem;
      object-fit: cover;
      border: solid 1rem $white;
      border-radius: 2rem;
      @include mq(l){
        width: 13rem;
        height: 13rem;
      }
    }
    .video--product {
      width: 5rem;
      height: auto;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  &__right {
    width: 90%;
    @include flexCenter();
    @include mq(l){
      width: 50%;
    }
    &--info {
      @include flexCenter();
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      color: $black;
      @include mq(l){
        gap: 1rem;
      }
      h2,
      input,
      select,
      label {
        font-size: 1rem !important;
        background-color: $white !important;
        border-radius: 2rem !important;
        padding: 0.5rem 0 !important;
        text-align: center !important;
        color: $black !important;
        width: 100% !important;
        margin: 0 !important;
        height: auto !important;
      }
      button {
        background-color: $black;
        color: $white;
        border-radius: 2rem;
        padding: 0.5rem 1rem;
        gap: 0;
        border: none;
        p {
          font-size: 1.1rem;
          width: 90%;
          margin: 0;
        }
        img{
          width: 2.2rem;
          height: auto;
        }
      }
      form{
        @include flexCenter();
        width: 100%;
        flex-direction: column;
        gap: 0.5rem;
        @include mq(l){
          gap: 1rem;
        }
      }
    }
  }
}
.destacados {
  margin-bottom: 3rem;
  .card--product{
    box-sizing: content-box;
    border: solid 4px $gray-lite;
    background-color: $black;
    .card--product__right--info{
      button{
        background-color: $gray-lite;
        color: $black;
      }
    }
  }
}