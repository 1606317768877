.back--arrow {
  display: flex;
  justify-content: flex-start;
  margin: 0 3rem 3rem;
  &__icon {
    width: 3rem;
    transition: all 500ms;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
    @include mq(l) {
      width: 5rem;
    }
  }
}
