.check--order__edit {
  background-color: $gray-lite;
  // border-radius: 2rem;
  padding: 5rem 2rem;
  min-height: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mq(l){
    min-height: 100vh;
  }
  &__producto {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 2rem;
    color: $black;
    margin-bottom: 1rem;
    .one,
    .two,
    .three,
    .four {
      background-color: $white;
      width: 21%;
      border-radius: 2rem;
      h2,
      h3 {
        font-size: 1.4rem;
      }
      p {
        font-size: 1.4rem;
      }
    }
    .one {
      @include flexCenter();
      img {
        display: block;
        margin: auto;
        width: 90%;
        height: 10rem;
        object-fit: cover;
        border-radius: 1rem;
      }
    }
    .two {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__content {
        @include flexCenter();
        gap: 2rem;
        text-align: center;
      }
    }
    .three {
      @include flexCenter();
      font-weight: 700;
      p {
        font-size: 2.5rem;
      }
    }
    .four {
      @include flexCenter();
      flex-direction: column;
      gap: 1rem;
      &__delete,
      &__edit {
        width: 70%;
        padding: 0.5rem 0;
        border-radius: 2rem;
        border: none;
        cursor: pointer;
        transition: 500ms all;
        color: $white;
        font-weight: 500;
        &:hover {
          transform: scale(1.1);
        }
      }
      &__delete {
        background-color: $red;
      }
      &__edit {
        background-color: $black;
      }
    }
  }
}
