@keyframes appear {
  from {
    opacity: 0;
    transform: rotate(0);
  }
  to {
    opacity: 1;
    transform: rotate(360deg);
  }
}

@keyframes outNode {
  from {
    transform: translateX(0);
}
to {
    transform: translateX(100vw);
  }
}
@keyframes inNode {
  from {
    transform: translateX(100%);
}
to {
    transform: translateX(0);
  }
}
